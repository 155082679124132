import dayjs from "dayjs";
import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";

export function generateTimeOptions(
  startTime: string,
  endTime: string,
  intervalMinutes: number
): SelectOptions[] {
  const options: SelectOptions[] = [];
  let currentTime = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);
  let slotId = 1;

  while (currentTime <= end) {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    options.push({
      id: `${formattedHours}:${formattedMinutes} ${ampm}`,
      name: `${formattedHours}:${formattedMinutes} ${ampm}`,
    });

    slotId++;
    currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
  }

  return options;
}

export function generateDateOptions(): SelectOptions[] {
  const dateOptions: SelectOptions[] = [];
  const today = dayjs();

  for (let i = 0; i <= 15; i++) {
    const currentDate = today.add(i, "day");
    const formattedDate = currentDate.format("ddd, D MMM YYYY");

    dateOptions.push({
      id: formattedDate,
      name: formattedDate,
    });
  }

  return dateOptions;
}
